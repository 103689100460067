$color-primary: #D9592F;
$border-primary: rgb(181, 181, 181);

@font-face {
    font-family: Helvetica-bold;
    src: url(../fonts/HelveticaNeueBold.otf);
}

@font-face {
    font-family: Helvetica-medium;
    src: url(../fonts/HelveticaNeueMedium.otf)
}

@font-face {
    font-family: Helvetica-light;
    src: url(../fonts/HelveticaNeueLight.otf);
}

@font-face {
    font-family: Helvetica-thin;
    src: url(../fonts/HelveticaNeueThin.otf)
}

$helevetica-medium: Helvetica-medium;
$helevetica-light: Helvetica-light;
$helevetica-thin: Helvetica-thin;
$helevetica-bold: Helvetica-bold;

.about_us_container {

    // banner begins here...
    .about_banner {
        width: 100%;
        height: 70svh;
        overflow: hidden;
        position: relative;

        img {
            width: inherit;
            height: inherit;
            object-fit: cover;
        }

        .overlay {
            width: 100%;
            height: 100%;
            background-image: linear-gradient(to top, black, transparent);
            position: absolute;
            top: 0;
        }
    }

    //content begins here...
    .container {
        width: 70%;
        margin: 0 auto;

        .intro_content {
            display: flex;
            margin-top: 100px;
            gap: 1.5rem;

            .flex {
                &:nth-child(1) {
                    width: 40%;

                    h1 {
                        margin-top: 0;
                        text-transform: uppercase;
                        font-size: clamp(1rem, 3vw, 3.5rem);
                    }
                }

                &:nth-child(2) {
                    width: 60%;



                    p {
                        line-height: 2;
                        font-size: .97rem;

                        &:nth-child(2) {
                            margin-top: 30px;
                        }

                        &:nth-child(3) {
                            margin-top: 60px;
                        }
                    }
                }
            }
        }

        .mid_content {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-content: space-between;
            align-items: center;
            margin-top: 100px;
            gap: 2rem;
    
    
            .mid_content_text {
                width: 100%;

                .heading{
                    img{
                        width: 30%;
                    }

                    h1{
                        font-size: clamp(1rem, 2.5vw, 3rem);
                       
                    }

                    
                }

               
    
                p {
                    font-size: .97rem;
                    line-height: 2;
    
                }
    
            }
    
            .mid_content_img {
                width: 100%;
    
                
    
                img {
                    width: 100%;
                    height: 80dvh;
                    object-fit: cover;
    
                }
            }
    
        }

        .promo_images{
            display: grid;
            grid-template-columns: auto auto;
            gap: 1rem;
            margin-top: 30px;

            img{
                width: 100%;
            }
        }
    
        .content_details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 150px;
    
            .details_on_vision {
                width: 30%;
                display: flex;
                justify-content: space-between;
                align-items: center;
    
    
                .vision_text {
                    margin: 0 50px 0 0;
                    line-height: 2;
    
                    .icon {
                        width: 80%;
                        display: grid;
                        place-items: center;
    
                        img {
                            width: 20%;
                        }
                    }
    
                    h2 {
                        font-size: clamp(1rem, 2vw, 2.5rem);
                        color: $color-primary;
                    }
                }
    
                .border_line1 {
                    p {
                        border-left: 1px solid rgb(203, 203, 203);
                        height: 65dvh;
    
                    }
    
                }
    
            }
    
    
    
            .details_on_values {
                width: 30%;
    
                .icon {
                    width: 80%;
                    display: grid;
                    place-items: center;
    
                    img {
                        width: 20%;
                    }
                }
    
                h1 {
                    margin-top: 30px;
                    font-size: clamp(1rem, 3vw, 3.5rem);
                    color: $color-primary;
                }
    
                p {
                    margin-top: 35px;
                    line-height: 2;

                    span{
                        font-family: $helevetica-medium;
                        
                    }
                }

               
            }
    
    
    
    
            .details_on_mission {
                width: 30%;
                display: flex;
                justify-content: space-between;
                align-items: center;
    
                .vision_text {
                    margin-left: 30px;
    
                    .icon {
                        width: 80%;
                        display: grid;
                        place-items: center;
    
                        img {
                            width: 20%;
                        }
                    }
    
                    h2 {
                        font-size: clamp(1rem, 2vw, 2.5rem);
                        color: $color-primary;
    
                    }
    
                    p {
                        line-height: 2;

                        
                    }
                }
    
                .border_line2 {
    
    
                    h1 {
                        border-left: 1px solid rgb(203, 203, 203);
                        height: 68dvh;
                    }
    
                }
            }
    
            // .border_line2{
            //     width: 2%;
            //     background-color: #D9592F;
            //     h1{
            //         border-right: 1px solid gray;
            //         width: 2%;
            //         height: 40dvh;
            //     }
            // }
    
        }

        .community_service{
            display: flex;
            align-items: center;
            gap: 5rem;
            margin: 100px 0;
            border-top: 1px solid rgb(224, 224, 224);
            border-bottom: 1px solid  rgb(224, 224, 224);

            .content,.community_image{
                width: 50%;
                line-height: 2;
                position: relative;

                h1{
                    font-family: $helevetica-bold;
                    color: $color-primary;
                    font-size: clamp(1rem,3vw,3rem);
                    margin-bottom: 0;
                }
                p{
                    font-size: .9rem
                }

                .filter_overlay{
                    width: 100%;
                    height: 100%;
                    background-color: $color-primary;
                    mix-blend-mode: color;
                    position: absolute;
                    top: 0;
                }

                img{
                    width: 100%;
                    object-fit: cover;
                    background-position: center;
                }

            }

            .community_image{
                p{
                    color: white;
                    line-height: 1.5;
                    margin: 20px;
                }
            }
        }

        //lead personnel begins here...
        .lead_personnel {
            margin: 30px 0;
            .lead_title {
                h1 {
                    border-bottom: 1px solid rgb(218, 218, 218);
                    padding-bottom: 30px;
                    font-size: clamp(1rem,3rem,3.5rem);
                    margin-bottom: 0;
                }
            }

            .personnel_container,.associates{
                display: grid;
                grid-template-columns: auto auto auto;
                gap: 3rem;
                .personnel{
                    // display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-bottom: 30px;

                    .personnel_image{
                       
                        width: 100%;
                        height: 40dvh;
                        margin-top: 50px;
                        img{
                            width: 100%;
                            height: inherit;
                            object-fit: cover;
                            object-position: top;
                            border: 1px solid rgb(219, 219, 219);
                            
                           
                         
                        }

                        
                    }

                    .name_position{
                        width: 100%;
                        .name{
                            font-size: clamp(1rem,1.5vw,1.5rem);
                            color: black;
                            margin-top: 15px;
                        }
                        p{
                            margin: 0;
                            font-size: .7rem;
                            color: gray;
                        }
                    }

                    .personnel_email{
                        display: none;
                        a{
                            font-size: clamp(1rem,2rem,1.8rem);
                        }
                       
                    }
                }
            }

            .associates{

                & > div:nth-child(1){
                    .name{
                        font-size: clamp(1rem,1.5vw,1.3rem) !important;
                        
                    }
                }

                div{  
                    &:nth-child(6){
                        img{
                            object-position:0 -70px !important;
                        }
                    }

                }

               
            }
            
        }
    }

   


}