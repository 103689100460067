$border-primary:rgba(181, 181, 181, 0.499);
$color-primary: #D9592F;

@font-face {
    font-family: Helvetica-bold;
    src: url(../fonts/HelveticaNeueBold.otf);
}

@font-face{
    font-family: Helvetica-medium;
    src:url(../fonts/HelveticaNeueMedium.otf)
}

@font-face{
    font-family: Helvetica-light;
    src: url(../fonts/HelveticaNeueLight.otf);
}

@font-face{
    font-family: Helvetica-thin;
    src: url(../fonts/HelveticaNeueThin.otf)
}
a{
    text-decoration: none;
    color: black;
}

.project_container{
    border-bottom: solid 1px $border-primary;
    height: 90vh;
  .container{
    width: 90%;
    margin: 0 auto;
    height: inherit;

    .project_flexbox{
        display: flex;
        height: inherit;

        .project_details{
            width: 40%;
            border-right: 1px solid $border-primary;
            display: grid;
            place-items: center;

            .container{
                h1{
                    font-size: clamp(1rem,3vw,3.5rem);
                    width: max(200px,30vw);
                    font-family: Helvetica-medium;
                    color: $color-primary;
                    margin-bottom: 0;
                    line-height: 1.3;
                    font-weight: 100;
                }

                h2{
                    font-family: Helvetica-medium;
                   
                    
                }
    
                .description{
                    font-size: .8rem;
                    line-height: 1.7;
                    color: rgb(92, 92, 92);
                }

                h3{
                    font-family: Helvetica-medium;
                    border-bottom: 1px solid $border-primary;
                    padding-bottom: 20px;
                    margin-top: 40px;
                }

                .project_summary{
                    display: grid;
                    grid-template-columns: auto auto;
                    gap: 1rem;

                    .flex{
                        width: 100%;
                        p{
                            margin-bottom: 0;
                            font-size: .8rem;
                            color: gray;
                           
                        }
                        h4{
                            margin-top: 10px;
                            width: 100%;
                            font-family: Helvetica-medium;
                        }
                    }

                   
                }

                .learn_more{
                    font-size: .8rem;
                }
            }
           
        }

        .project_images{
            width: 60%;
            height: inherit;
            display: grid;
            place-items: end;
            position: relative;
            

            .container{
             
                margin: 0;
                overflow: hidden;
              

                .project_viewport{
                    display: flex;
                    height: 90vh;
                    flex-direction: column;
                    gap: 2rem;
                    margin-top: 50px;
                    overflow-y: auto;
                    scroll-snap-type: y mandatory;

                    .project{
                        width: 100%;
                        height: 70vh;
                        background-color: red;
                        // margin-top: 30px;
                        display: grid;
                        place-items: center;
                        position: relative;
                        flex: none;
                        scroll-snap-align: start;

                        .overlay{
                            width: inherit;
                            height: inherit;
                            position: absolute;
                            background-image: linear-gradient(to top,black,transparent);
                            opacity: .7;
                            mix-blend-mode: multiply;
                            z-index: 1;
                        }

                        button{
                           
                            all: unset;
                            color: white;
                            border: 1px solid white;
                            padding: 10px 40px;
                            position: relative;
                            z-index: 2;
                            cursor: pointer;
                            transition: .3s;

                            &:hover{
                                background-color: white;
                                color: black;
                                padding: 20px 55px;
                            }
                        }
                    }

                    .project_1{
                        background: url(../images/project1.webp);
                        background-size: cover;
                    }

                    .project_2{
                        background: url(../images/abetitfi_container/render_1.webp);
                        background-size: cover;
                    }

                    .project_3{
                        background: url(../images/josh/render_1.webp);
                        background-size: cover;
                    }

                    .project_4{
                        background: url(../images/rosey/render_1.webp);
                        background-size: cover;
                    }

                }

                .images_footer{
                    width: inherit;
                    height: 15vh;
                    background-image: linear-gradient(to top,white,transparent);
                    position: absolute;
                    top: 100%;
                    transform: translateY(-100%);
                    display: grid;
                    place-items: center;
                    z-index: 5;
    
                    .line{
                        text-align: center;
                        
                       h1{
                        margin: 0;
                        span{
                            font-weight: bolder;
                            font-family: Helvetica-bold;
                        }
                       }
    
                       p{
                        margin: 0;
                       }
                    }
                   
                }
            }

            
        }
    }
  }
}

.project_contact{
    .contact_container{
        .project_flex1{
            width: 41% !important;

            img{
                height: 100%;
                object-fit: cover;
            }
        }

        .project_flex2{
            width: 59% !important;

           
        }

        
    }   
}