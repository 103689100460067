$color-primary: #D9592F;

@media screen and (max-width:500px) {
    body {
        
        section {

            .balance{
                height: 65vh !important;
            }

            //Nav
            nav {
                .container {
                    width: 100% !important;

                    .flexbox {
                        justify-content: space-between;
                        .flex {
                            &:nth-child(1) {
                                width: 50% !important;
                                padding: 15px 0;

                                .logo_container {
                                    display: grid;
                                    place-items: center;
                                    padding-left: 20px;
                                }
                            }

                            &:nth-child(2) {
                                display: none !important;
                            }

                            &:nth-child(3) {
                                display: block !important;
                                .mobile_menu {
                                    width: 100px;
                                    height: max(80px,10vh);
                                    background-color: $color-primary;
                                    display: grid;
                                    place-items: center;
                                    cursor: pointer;

                                    .line_holder{
                                        width: 50%;
                                        position: relative;
                                        .line{
                                            width: 100%;
                                            border: solid .5px white;
                                            margin-top: 15px;
                                            transition: .5s;

                                            &:first-child{
                                                margin-top: 0;
                                            }
                                        }
                                    }

                                   
                                }

                            }
                        }
                    }
                }
            }

            //Footer
            .footer {
                .container {
                    flex-direction: column;
                }

            }

            //Banner
            .banner{
                .banner_footer{
                    .banner_navigation{
                        
                        
                        .design_build{
                            display: none !important;
                            h2{
                                margin-left: 0 !important;
                            }
                        }

                        .mobile_banner_caption{
                            display: block !important;

                            h2{
                                font-size: 1.7rem !important;
                                line-height: 1.7;
                            }
                        }

                        .construction_management,.interior_design{
                           display: none !important;
                        }
                    }

                    .flexbox{
                        .flex{
                            .mini_nav{
                                display: none !important;
                            }

                            p{
                                font-size: clamp(0.5rem, 1vw, .8rem) !important;
                            }
                        }

                        .flex:nth-child(3){
                            display: none ;
                        }
                    }
                }
            }

            //featured projects
            .featured_projects{
                .container{
                    .welcome{

                        p{
                            width: 100% !important;
                        }
                    }

                    .team_container{
                        .container{
                            width: 100% !important;
                        }
                    }

                    .latest_project{
                        .flexbox{
                            flex-direction: column-reverse;

                            .flex{
                                width: 100% !important;

                                h1{
                                    margin-top: 40px !important;
                                }

                                .corousel{
                                    .viewport{
                                        .image_trey{
                                            flex-direction: column;
                                            a{
                                                width: 100% !important;
                                                border-bottom: 1px solid rgb(209, 209, 209);
                                                padding-bottom: 20px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .featured_content{
                        .flexbox{
                            display: none !important;
                        }
                    }
                }
            }

            //service
            .service{
                .container{
                    width: 90% !important;

                    .service_flexbox{
                        flex-direction: column;

                        .flex{
                            width: 100% !important;
                        }
                    }

                    .content{
                        width: 100% !important;
                        font-size: .9rem;
                    }
                }
            }

            //breaker
            .breaker{
                display: none;
            }

            //Foundation
            .foundation{
                .content_container{
                    .content{
                        width: 75% !important;
                    }
                }
            }

            //Contact
            .contact{
                .contact_container{
                    flex-direction: column;

                    .flex{
                        width: 100% !important;

                        .content_details{
                            width: 80% !important;
                            padding: 40px 0;
                            .contact_heading{
                                justify-content: center;
                            }
                        }
                    }
                }
            }

            //Footer
            .footer{
                .container{
                    width: 80% !important;
                    .flex{
                        width: 100% !important;

                        .copyright{
                            display: none;
                        }

                        .mobile_copyright{
                            display: block !important;
                            line-height: 4;
                            font-size: .7rem !important;
                        }
                    }
                }
            }

        }

        .service_container{

            .service_banner{
                height: 90dvh;

                .mobile_manu_container{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    background-color: white;
                    z-index: 2;
                    transform: translate(100%);
                    display: block;

                    .mobile_manu{
                        
                        .div{
                            width: 100%;
                            border-bottom: 1px solid rgb(205, 205, 205);

                            h1{
                                text-align: right;
                                font-size: 1rem; 
                                padding: 15px 18px 15px 0;
                                text-transform: uppercase;
                                transform: translate(30%);
                            }
                        }
                    }

                    .mobile_manu_contact{
                        width: 80%;
                        margin: auto;
                        .mobile_contact{
                            width: 80%;
                            margin-right: 30px;
                            position: absolute;
                            right: 0;
                            margin-top: 50px;
                            opacity: 0;

                            .manu_location{
                                border-bottom: 1px solid red;
                                
                                p{
                                    text-align: right;
                                }
                                h1{
                                    text-align: right;
                                    font-size: .9rem;
                                }
                            }
    
                            .manu_email{
                                border-bottom: 1px solid red;
                                p{
                                    text-align: right;
                                }
                                h1{
                                    text-align: right;
                                    font-size: .9rem;
                                }
                            }
    
                            .manu_contact{
                                border-bottom: 1px solid red;
                                
                                p{
                                    text-align: right;
                                }
                                h1{
                                    text-align: right;
                                    font-size: .9rem;
                                }
                            }

                            .menu_location,.menu_contact,.manu_email{
                                p,h1{
                                    letter-spacing: 1.2;
                                }
                            }
                        }

                       
                    }


                    .social_media{
                        position: absolute;
                        top: 80%;
                        right: 10%;
                        nav{
                            display: flex;
                            justify-content: right;
                            align-items: center;
                            gap: 20px;

                            p{
                                font-size: .7rem;
                            }

                            h1{
                                font-size: .7rem;
                            }

                        }
                    }

                  .media_developer{
                    p{
                        position: absolute;
                        top: 83%;
                        right: 10%;
                        font-size: 12px;
                        font-weight: bold;
                    }
                  }

                  


                }
                .service_banner_text{
                    display: block;
                    width: 90%;
                    top: 95%; 
                    transform: translateY(-95%);

            
                    

                    .first_text{
                        width: 100%;
                        h1{
                          font-size: 2rem;  
                        }

                        p{
                            font-size: .7rem;
                            letter-spacing: 1;
                        }
                    }
                    .second_text{
                        p{
                            line-height: 1.7;
                            letter-spacing: .9;
                            font-size: .8rem;
                            display: none;
                        }
                    }
                }
            }

            .text_container{
              flex-direction: column;
              width: 85%;
    
                .div1{
                width: 100%;
                text-align: center;
                margin-bottom: 30px;
                display: grid;
                place-items: center;
                    h1{
                        text-align: center;
                        color: #000;
                        font-size: 2rem;
                        margin: auto;
                        text-align: center;
                        padding-bottom: 10px;
                    }

                    .line{
                        width: 50px;
                        border-top: solid 1px gray;
                        
                    }
                }
    
                .div2{
                    width: 100%;
                    text-align: center;
    
                    p{
                        border-left: none;
                        font-size: .9rem;
                        line-height: 1.7;
                        letter-spacing: 1px;
                        text-align: center;
                        padding: 0;
                        
                    }
                }
            }

            .container{
                .service_flexbox{
                    grid-template-columns:auto;

                    .service_flex{
                        flex-direction: column;

                        .img{
                            width: 100%;
                        }

                        .content{
                            width: 80%;
                            padding-bottom: 20px;

                            p{
                                letter-spacing: .9;
                                font-size: .8rem;
                            }
                        }
                    }
                }

             
            }
     
        }

        .projects_container{
            .container{
                width: 95%;

            }
            .project_intro{
                .container{
                    display: block;
                    justify-items: center;

                    .title{
                        width: 70%;
                        h1{
                            text-align: center;
                            margin-bottom: 30px;
                            font-size: 1.3rem;
                            font-weight: 900;
                        }
                    }

                    .content{
                        width: 95%;
                        line-height: 1.4rem;

                        p{
                            &:last-child{
                                margin-top: 50px;
                            }
                        }
                    }
                }
            }

            .project_content{
                margin: 5rem 0;
                .content1{
                    .container{
                        .flexbox{
                            display: block;
                            
                            .flex:first-child{
                                width: 100%;
                                margin-bottom: 10px;
                            }

                            .flex:last-child{
                                width: 100%;
                            }
                        }
                    }
                }

                .content2{
                    .content{
                        width: 100%;
                        h1{
                            width: 100%;
                        }
                    }

                    .container{

                    .content_img2  {     
                        height: 20vh;
                    }
                        .content_img{
                            margin-top: 40px;
                        }
                    }

                }
            }

            .project_carousel{
                margin: 20px 0;
                padding: 20px 0;
                 .container{
                     .carousel_flexbox{
                        display: block;

                        .flex:first-child{
                            width: 100%;

                            .content{
                                .carousel_count{
                                    h1{
                                        text-align: center;
                                    }
                                }
                            }
                        }

                        .flex:last-child{
                            width: 100%;

                            .arrow{
                                background-image: none;
                            }

                            .arrow:last-child{
                                background-image: none;
                            }
                        }
                    } 
                }
            }
        }

        .about_us_container{

            .about_banner{
                height: 75svh;
            }

            .container{
                .lead_personnel{
                    
                    .personnel_container{
                        display: block;
                    }
                    .lead_title{
                        .associates{
                            display: block;
                        }
                        h1{
                            font-size: 2rem;  
                          }
                    }
                }

                .intro_content{
                    display: block;

                    .flex:first-child{
                        width: 100%;
                    }

                    .flex:last-child{
                        width:100%;
                    }
                }

                .mid_content{
                    display: block;

                 .mid_content_text{
                    .heading h1{
                        font-size: 1.5rem;
                    }
                 }
                    
                    .mid_content_img{
                        img{
                            height: 40dvh;
                        }
                    }
                }

                .content_details{
                    display: block;
                    margin-top: 50px;

                    .details_on_vision{
                        width: 100%;

                        .vision_text{
                            margin: 0;

                            .icon{
                               place-items: left;
                               display: flex;

                        
                            }
                        }

                        .border_line1 p{
                            border-left: none;
                            height: none;
                        }
                    }

                    .details_on_values{
                        width: 100%;
                            .icon{
                                place-items: left;
                                display: flex;
                             }
                    }

                    .details_on_mission{
                        width: 100%;

                        .vision_text{
                            margin: 0;

                            .icon{
                                place-items: left;
                                display: flex;
                                margin-bottom: 20px;
                             }
                            
                        }

                        .border_line2 h1{
                            border-left: none;
                            height: none;
                        }
                    }
                }

                .community_service{
                    margin: 50px 0;
                    display: block;

                    .content{
                        width: 100%;
                        h1{
                            font-size: 1.5rem;
                        }
                    }

                    .community_image{
                        width: 100%;
                        margin-top: 30px;

                        p{
                        padding-bottom:20px;
                        }
                    }
                }














                
            }

        }

        .mobile_menu_holder{
            display: block !important;
            width: 100%;
            height: 89.5svh;
            position: absolute;
            top: 100%;
            transform: translateY(-100%);
            z-index: 10;
            overflow: hidden;
            pointer-events: none;
           
            
            .mobile_manu_container{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                background-color: white;
                z-index: 2;
                transform: translate(100%);
                display: block;
                border-top: 1px solid  rgb(205, 205, 205);
                pointer-events: auto;
                touch-action: none;

                .mobile_manu{
                    pointer-events: auto;
                    
                    .div{
                        width: 100%;
                        border-bottom: 1px solid rgb(205, 205, 205);

                        h1{
                            text-align: right;
                            font-size: clamp(.8rem,1vw,1rem); 
                            padding: 15px 18px 15px 0;
                            text-transform: uppercase;
                            transform: translate(30%);
                            font-weight: bold;
                        }
                    }
                }

                .mobile_manu_contact{
                    width: 80%;
                    margin: auto;
                    .mobile_contact{
                        width: 80%;
                        margin-right: 30px;
                        position: absolute;
                        right: 0;
                        margin-top: 50px;
                        opacity: 0;

                        .manu_location{
                            border-bottom: 1px solid red;
                            
                            p{
                                text-align: right;
                                font-size: clamp(.7rem,1vw,1rem);
                            }
                            h1{
                                text-align: right;
                                font-size: .9rem;
                            }
                        }

                        .manu_email{
                            border-bottom: 1px solid red;
                            p{
                                text-align: right;
                                font-size: clamp(.7rem,1vw,1rem);
                            }
                            h1{
                                text-align: right;
                                font-size: .9rem;
                            }
                        }

                        .manu_contact{
                            border-bottom: 1px solid red;
                            
                            p{
                                text-align: right;
                                font-size: clamp(.7rem,1vw,1rem);
                            }
                            h1{
                                text-align: right;
                                font-size: .9rem;
                            }
                        }

                        .menu_location,.menu_contact,.manu_email{
                            p,h1{
                                letter-spacing: 1.2;
                            }
                        }
                    }

                   
                }


                .social_media{
                    position: absolute;
                    top: 80%;
                    right: 10%;
                    nav{
                        display: flex;
                        justify-content: right;
                        align-items: center;
                        gap: 20px;

                        p{
                            font-size: .7rem;
                        }

                        h1{
                            font-size: .7rem;
                        }

                    }
                }

              .media_developer{
                p{
                    position: absolute;
                    top: 83%;
                    right: 10%;
                    font-size: 12px;
                    font-weight: bold;
                }
              }

              


            }
        }

        .project_container{
            position: relative;
            .container{
                .project_flexbox{
                    flex-direction: column-reverse;
                    

                    .project_details{
                        width: 100%;
                        display: none;
                    }

                    .project_images{
                        width: 100%;

                        .container{
                            width: 100%;

                            .project_viewport{
                                height: 81svh;
                            }
                        }
                    }
                }
            }

           
        }

        .projects_container{
            .project_intro{
                .container{
                    .content{
                        p{
                            text-align: center;
                        }
                    }
                }
            }
        }

        .main_navigation_content{
            .navigation_content-flex{
                margin-top: 15px;
                h1{
                    font-size: 1rem;
                }

                h2{
                    font-size: 1.5rem;
                }
            }

            .navigation_content{
                margin-top:20px;
                width: 90%;
                .navigation_content1{
                    display: block;
                    margin-bottom: 20px;

                    a{
                        .navigation_div_for_grace{
                            display: block;
                            width: 85%;
                            margin: auto;

                            .img_on_grace_page img{
                                
                                height: 10dvh;
                            }

                            .details_on_grace_page{
                                h1{
                                    font-size: 1rem;
                                }

                                p{
                                    font-size: .8rem;
                                }
                            }

                        }
                        .navigation_div_for_josh{
                            margin-top: 20px;
                            display: block;
                            width: 85%;
                            margin: auto;

                            .img_on_josh_page img{
                                width: 125%;
                                height: 10dvh;
                            }

                            
                            .details_on_josh_page{
                                h1{
                                    font-size: 1rem;
                                }

                                p{
                                    font-size: .8rem;
                                }
                            }
                        }

                    }
                }

                .navigation_content2{
                    display: block;

                    a{
                        .navigation_div_for_rosey{
                            display: block;
                            width: 85%;
                            margin: auto;

                            .img_on_rosey_page img{
                                width: 125%;
                                height: 10dvh;
                            }
                            .details_on_rosey_page{
                                h1{
                                    font-size: 1rem;
                                }

                                p{
                                    font-size: .8rem;
                                }
                            }

                        }
                        .navigation_div_for_Abetifi{
                            display: block;
                            width: 85%;
                            margin: auto;

                          
                            .img_on_Abetifi_page {

                                img {
                                    width: 125%;
                                    height: 10dvh;
                                }
                            }
                            
                            .details_on_Abetifi_page{
                                h1{
                                    font-size: 1rem;
                                }

                                p{
                                    font-size: .8rem;
                                }
                            }
                        }

                    }
                }
            }
        }

        .navigation_content_div{
            left: 0;
            .navigation_img{
                transform: scale(.8);
                background-color: white;

                
            }
        }


    }   

      
}

@media screen and (max-width:376px) {
    body{
        .mobile_menu_holder{
            .mobile_manu_container{
                .mobile_manu_contact{
                    .mobile_contact{
                        margin-top: auto;
                    }
                }
            }
        }
    }
}