$color-primary: #D9592F;
$border-primary:rgb(181, 181, 181);

@font-face {
    font-family: Helvetica-bold;
    src: url(../fonts/HelveticaNeueBold.otf);
}

@font-face{
    font-family: Helvetica-medium;
    src:url(../fonts/HelveticaNeueMedium.otf)
}

@font-face{
    font-family: Helvetica-light;
    src: url(../fonts/HelveticaNeueLight.otf);
}

@font-face{
    font-family: Helvetica-thin;
    src: url(../fonts/HelveticaNeueThin.otf)
}

$helevetica-medium:Helvetica-medium;
$helevetica-light:Helvetica-light;
$helevetica-thin:Helvetica-thin;
$helevetica-bold:Helvetica-bold;


.main_navigation_content{
        width: 100%;
        // height: 100dvh;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100dvh;
        position: fixed;
        z-index: 3;
        background-color: white;
        opacity: 0;
        display: none;
        

        
        .navigation_content-flex{
            display: flex;
            align-items: center;
            position: absolute;
            left: 5%;
            top: 0;
            gap: 20px;
            margin-top: 100px;

            h1{
                font-size: clamp(2rem,3vw,2rem);
                color: hsl(0, 0%, 50%);
                font-family: $helevetica-light;
                font-weight: 100;
            }


            p{
                height: 3dvh;
                border-left: 2px solid grey;
            }

            h2{
                font-size: clamp(2rem,3vw,2rem);
                color: hsl(0, 0%, 50%);
                font-family: $helevetica-light;
                font-weight: 100;
            }



        }
        
    .navigation_content{
        background-color: white;
        

        
        .navigation_content1{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 40px;
            gap: 50px;
            
    
          a{
            width: 50%;
            .navigation_div_for_grace{
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 50px;
    
    
                .img_on_grace_page{
                    width: 100%;
    
                    img{
                        width: 100%;
                        object-fit: cover;
                        height: 20dvh;
                    }
                }
    
                .details_on_grace_page{
                    h1{
                        font-size: clamp(.5rem,2vw,1rem);
                        margin-bottom: 0;
                        color: #D9592F;
                    }
    
                    p{
                        margin-top: 0;
                        font-size: 10px;
                    }
                }
            }
          }
    
            a{
                width: 50%;
                .navigation_div_for_josh{
                    display: flex;
                    align-items: center;
                    gap: 50px;
        
        
                    .img_on_josh_page{
                        width: 80%;
        
                        img{
                            width: 100%;
                            object-fit: cover;
                            height: 20dvh;
                        }
                    }
        
                    .details_on_josh_page{
                        h1{
                            font-size: clamp(.5rem,2vw,1rem);
                            margin-bottom: 0;
                            color: #D9592F;
                        }
        
                        p{
                            margin-top: 0;
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    
        .navigation_content2{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 50px;
           
            a{
                width: 50%;
                .navigation_div_for_rosey{
                    
                    display: flex;
                    align-items: center;
                    gap: 50px;
        
        
                    .img_on_rosey_page{
                        width: 80%;
        
                        img{
                            width: 100%;
                            object-fit: cover;
                            height: 20dvh;
                        }
                    }
        
                    .details_on_rosey_page{
                        h1{
                            font-size: clamp(1rem,2vw,1rem);
                            margin-bottom: 0;
                            color: #D9592F;
                        }
        
                        p{
                            margin-top: 0;
                            font-size: 10px;
                        }
                    }
                }
            }
    
          a{
            width: 50%;
            .navigation_div_for_Abetifi{
                
                display: flex;
                align-items: center;
                gap: 50px;
    
    
                .img_on_Abetifi_page{
                    width: 80%;
    
                    img{
                        width: 100%;
                        object-fit: cover;
                        height: 20dvh;
                    }
                }
    
                .details_on_Abetifi_page{
                    h1{
                        font-size: clamp(.5rem,2vw,1rem);
                        margin-bottom: 0;
                        color: #D9592F;
                    }
    
                    p{
                        margin-top: 0;
                        font-size: 10px;
                    }
                }
            }
          }
        }
    
       
    }
}


.navigation_content_div{
    position: fixed;
    top: 90%;
    left: 5%;
    z-index: 4;
    cursor: pointer;
    
    .navigation_img{
        width: 70%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid rgba(128, 128, 128, 0.486);
        border-radius: 80px;
        padding: 0 15px;
        background-color: white;
        transition: .3s;

            img{
                width: 15%;
            }

            &:hover{
                background-color: black;
                color: white;

                img{
                    filter: invert(1);
                }
            }
    }
}

.projects_container{

    .container{
        width: 70%;
        margin: 0 auto;
    }

    // banner styling begin here
    .project_banner{
        width: 100%;
        height: 70svh;
        overflow: hidden;
        position: relative;

        .overlay{
            width: inherit;
            height: inherit;
            background-image: linear-gradient(to top,rgba(4, 4, 4, 0.574),transparent,transparent);
            top: 0;
            position: absolute;
    
           
        }

        img{
            width: 100%;
            height: inherit;
            object-fit: cover;
            object-position: top;
        }
        
    }

    // project intro styline begin here
    .project_intro{
        margin: 50px 0;
     
        .container{
            width: 90%;
            margin: auto;
            //border-bottom: 1px solid rgb(227, 227, 227);

            display: flex;
            justify-content: space-between;
            align-items: center;

            .title{
                width: 25%;

                h1{
                    font-size: clamp(1rem,3vw,2.5rem);
                    line-height: 1.3;
                    font-family: $helevetica-light;
                    // color: $color-primary;
                }
            }

            .content{
                width: 70%;
                p{
                    font-size: 1.05rem;
                    line-height: 1.8;
                    color: rgb(71, 71, 71);
                }
            }
        }
    }

    //Breaker styling begins here...
    .project_breaker{
        margin-top: 100px;
        opacity: 0;
        transform: translateY(50px);
        transition: .7s ease-out;
        

        .container{
            .video_container{
                width: 100%;
                display: none;

                video{
                    width: 100%;
                    border: 1px solid rgb(225, 225, 225);
                }

                img{
                    width: 100%;
                }
            }
        }

       
    

       
    }

    .show{
        opacity: 1;
        transform: translateY(0);
    }

    //Projet Carousel begins here...
    .project_carousel{
        margin: 100px 0;
        background-color: rgba(245, 245, 245, 0.616);
        padding: 50px 0;
        .container{
            width: 90%;
            .carousel_flexbox{
                display: flex;
                justify-content: space-between;
                align-items: center;

                .flex{

                    &:first-child{
                        width: 20%;

                        .content{
                            .carousel_count{
                                margin-top: 50px;
                                h1{
                                    font-size:clamp(1px,3vw,2.5rem);
                                    color:$color-primary
                                }

                               
                            }

                            p{
                                margin-top: 10px;
                                font-size: .95rem;
                                line-height: 1.7;
                                color: gray;
                            }
                        }
                    }

                    &:last-child{
                        width: 75%;
                        display: flex;
                        justify-content: space-between;
                        gap: 1rem;
                        // align-items: center;

                        .arrow{
                            width: 5%;
                            cursor: pointer;
                            background-image: linear-gradient(to right, rgb(231, 231, 231),transparent);
                            display: grid;
                            place-items: center;
                            // border: 1px solid rgb(205, 205, 205);
                         
                            
                            
                            &:last-child{
                                background-image: linear-gradient(to left, rgb(231, 231, 231),transparent);
                                img{
                                    transform: scale(-1);
                                }
                            }
                        }

                        .viewport{
                            width: 90%;
                            overflow: hidden;

                            .trey{
                                display: flex;
                                gap: 1rem;
                                transition: 1s ease-in-out;

                                img{
                                    width: 80%;
                                    height: 450px;
                                    object-fit: cover;
                                    object-position: center;
                                    flex: none;
                                }
                            }
                        }

                    }
                }
            }
        }
    }

    .grace_carousel,.grace_gallery{
        display: none !important;
    }

    //Project content begins here..
    .project_content{
        margin: 10rem 0;
        .content1{
            opacity: 0;
            transform: translateY(50px);
            transition: .9s ease-out;
            .container{
                .flexbox{
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    gap: 2rem;

                    .flex{
                        &:first-child{
                            width: 40%;

                            h1{
                                font-size: clamp(1.3rem, 2.5vw,3rem);
                                margin-top: 0;
                                color: $color-primary;
                                font-weight: 500;
                            }

                            p{
                                line-height: 1.7;
                                width: max(100px,90%);
                                font-size: 1.05;
                                color: gray;
                            }
                           
                        }

                        &:last-child{
                            width: 55%;
                            height: 50vh;

                            img{
                                width: 100%;
                                height: inherit;
                                object-fit: cover;
                            }
                        }
                        
                    }
                }
            }
        }

       

        .content2{
            
            .container{
                border-bottom: 1px solid rgb(206, 206, 206);
                .content_img{
                    width: 100%;
                    margin-top: 100px;
                    

                    img{
                        width: 100%;
                        opacity: 0;
                        
                        transition: .9s ease-out;
                    }

                    .show{
                        opacity: 1;
                        transform: translateY(0);
                    }
                }

                .content_img2{
                    width: 100%;
                    display: grid;
                    height: 50vh;
                    gap: 1.5rem;
                    opacity: 0;
                    margin-top: 15px;
                    transition: .9s ease-out;
                    grid-template-columns: auto auto;
                    transform: translateY(50px);

                    img{
                        width: 100%;
                        height:inherit;
                        object-fit: cover;
                    }

                    
                }

                .show{
                    opacity: 1;
                    transform: translateY(0);
                }
            }

            .content{
                width: 50%;
                margin-top: 80px;

                h1{
                    font-size: clamp(1rem, 2.5vw, 3rem);
                }

                p{
                    width: max(300px,80%);
                }
            }
        }

        .content3{
            opacity: 0;
            transform: translateY(50px);
            transition: .9s ease-out;
            .container{
                margin-top:80px ;
            }
        }

        .show{
            opacity: 1;
            transform: translateY(0);
        }
    }

    //Project gallery begins here...
    .project_gallery{
        display: grid;
        place-items: center;
        text-align: center;
        margin-top: 7rem;
        width: 100%;

        .gallery_content{
            margin-bottom: 30px;
            p{
                color: gray;
            }
            h1{
                &:last-child{
                    font-size: clamp(1rem,3vw,3rem);
                }
            }
        }

        .gallery_holder{
            background-image: linear-gradient(to top,rgb(228, 228, 228),transparent);
            width: inherit;
            
           

            .gallery{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 2rem;
                padding-bottom: 100px;

                .viewport{
                    width: 70%;
                    overflow: hidden;

                    .trey{
                        display: flex;
                        transition: .7s ease-in-out;
                        img{
                            width: 100%;
                            height: 60svh;
                            object-fit: cover;
                            flex: none;
                        }
                    }
                }

                .arrow{
                    cursor: pointer;
                    &:last-child{
                        transform: scale(-1);
                    }
                }
            }
        }
    }
}