@font-face {
    font-family: Helvetica-bold;
    src: url(../fonts/HelveticaNeueBold.otf);
}

@font-face {
    font-family: Helvetica-medium;
    src: url(../fonts/HelveticaNeueMedium.otf)
}

@font-face {
    font-family: Helvetica-light;
    src: url(../fonts/HelveticaNeueLight.otf);
}

@font-face {
    font-family: Helvetica-thin;
    src: url(../fonts/HelveticaNeueThin.otf)
}

$color-primary: #D9592F;

$helevetica-medium: Helvetica-medium;
$helevetica-light: Helvetica-light;
$helevetica-thin: Helvetica-thin;
$helevetica-bold: Helvetica-bold;



.service_container {

    .service_banner {
        width: 100%;
        height: 92vh;
        background: url(../images/service_banner.webp);
        background-position: center;
        background-size: cover;
        position: relative;
        overflow: hidden;

        .mobile_manu_container{
            display: none;
        }

        .overlay {
            width: inherit;
            height: inherit;
            position: absolute;
            background-image: linear-gradient(to top, rgb(0, 0, 0), transparent, transparent);

            &:nth-child(2) {
                width: inherit;
                height: inherit;
                background-image: linear-gradient(to top, rgba(0, 0, 0, 0.774), transparent, transparent);
                transform: scaleY(-1);
                opacity: .6;
            }
        }

        .color_overlay {
            width: 100%;
            height: 100%;
            background-color: $color-primary;
            mix-blend-mode: color;
            position: absolute;
            top: 0;
            z-index: 0;
        }

        .service_banner_text {
            width: 90%;
            margin: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            top: 75%;
            left: 5%;



            .first_text {
                width: 20%;

                h1 {
                    color: rgb(240, 95, 12);
                    font-size: clamp(1rem, 3vw, 3rem);
                    margin-bottom: 0;


                }

                width: 80%;

                p {
                    color: white;
                    margin-top: 0;
                    text-align: left;
                }
            }

            .second_text {
                margin-top: 20px;

                p {
                    color: white;
                    text-align: left;
                    line-height: 1.7;
                }
            }

        }

    }

    .container {
        width: 90%;
        margin: 100px auto;

        .service_flexbox {
            display: grid;
            grid-template-columns: auto auto;
            gap: 2rem;

            .service_flex {
                display: flex;
                margin-top: 30px;
                background-color: $color-primary;
                align-items: center;
                justify-content: center;

             
                

                .content{
                    width: 40%;
                   padding: 0 50px;
                   color: white;

                   h1{
                    font-family: $helevetica-bold;
                   }
                   
                   p{
                    font-size: .9rem;
                   }
                }

                .img {
                    width: 70%;
                    height: 40vh;

                    img {
                        width: 100%;
                        height: inherit;
                        object-fit: cover;
                        object-position: center;
                    }

                }
            }
        }
    }

    .text_container {
        display: flex;
        width: 90%;
        margin: auto;
        justify-content: space-between;
        align-items: center;
        margin-top: 120px;



        .div1 {
            width: 25%;

            h1 {
                font-size: clamp(1rem, 3vw, 3rem);
                text-align: left;
                color: #D9592F;
                margin: 0;
            }
        }



        .div2 {
            width: 65%;

            p {
                border-left: 1px solid rgb(208, 208, 208);
                text-align: left;
                padding-left: 50px;
                font-size: .95rem;
                color: rgb(84, 84, 84);
                line-height: 2;
            }
        }
    }




    .designs_div {
        width: 90%;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 150px;

        .design_text {
            width: 30%;
            height: 300px;

            p {
                width: 90%;
                text-align: left;
                line-height: 1.8;
                margin-top: 0;
                font-size: 1.3rem;
                font-family: $helevetica-light;
                font-weight: bold;
            }
        }

        a {
            width: 30%;
            text-decoration: none;
            color: black;


            .design_img {
                .filter_overlay {
                    width: 100%;
                    height: 100%;
                    background-color: $color-primary;
                    mix-blend-mode: color;
                    opacity: .5;
                }
            }

            .design_image_div1 {


                .design_img {
                    width: 100%;
                    height: 300px;
                    background-image: url(../images/service_1.jpg);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    // img{
                    //     width: 100%;
                    // }

                    // .service_2{
                    //     height: 400px;
                    // }

                }

                .design_details {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;

                    .details_for_design {
                        width: 87%;

                        h3 {
                            text-align: left;
                            color: #D9592F;
                            margin: 35px 0 10px 0;
                        }

                        p {
                            margin: 0;
                            text-align: left;
                            font-size: clamp(.6rem, 1vw, .8rem);
                        }
                    }

                    .detail_arrow {
                        width: 10%;

                        img {
                            width: 100%;
                        }
                    }
                }
            }

            .design_image_div2 {



                .design_img {
                    width: 100%;
                    height: 300px;
                    background-image: url(../images/service_2.jpg);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    // img{
                    //     width: 100%;
                    // }

                    // .service_2{
                    //     height: 400px;
                    // }

                }

                .design_details {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;

                    .details_for_design {
                        width: 87%;

                        h3 {
                            text-align: left;
                            color: #D9592F;
                            margin: 35px 0 10px 0;
                        }

                        p {
                            margin: 0;
                            text-align: left;
                            font-size: clamp(.6rem, 1vw, .8rem);
                        }
                    }

                    .detail_arrow {
                        width: 10%;

                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }






    }

    .construction_div {
        display: flex;
        width: 90%;
        margin: auto;
        justify-content: space-between;
        align-items: center;
        margin-top: 150px;

        a {
            width: 30%;
            text-decoration: none;
            color: black;

            .design_img {
                .filter_overlay {
                    width: 100%;
                    height: 100%;
                    background-color: $color-primary;
                    mix-blend-mode: color;
                    opacity: .5;
                }
            }

            .design_image_div3 {


                .design_img {
                    width: 100%;
                    height: 300px;
                    background-image: url(../images/project3.webp);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    // img{
                    //     width: 100%;
                    // }

                    // .service_2{
                    //     height: 400px;
                    // }

                }

                .design_details {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;

                    .details_for_design {
                        width: 87%;

                        h3 {
                            text-align: left;
                            color: #D9592F;
                            margin: 35px 0 10px 0;
                        }

                        p {
                            margin: 0;
                            text-align: left;
                            font-size: clamp(.6rem, 1vw, .8rem);
                        }
                    }

                    .detail_arrow {
                        width: 10%;

                        img {
                            width: 100%;
                        }
                    }
                }
            }

            .design_image_div4 {

                .design_img {
                    width: 100%;
                    height: 300px;
                    // background-image: url(../images/service_3.webp);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    background: url(../images/service_5.webp);
                    background-size: cover;
                    // img{
                    //     width: 100%;
                    // }

                    // .service_2{
                    //     height: 400px;
                    // }

                }

                .design_details {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;

                    .details_for_design {
                        width: 87%;

                        h3 {
                            text-align: left;
                            color: #D9592F;
                            margin: 35px 0 10px 0;
                            font-size: clamp(1rem, 1vw, 1rem);
                        }

                        p {
                            margin: 0;
                            text-align: left;
                            font-size: clamp(.6rem, 1vw, .8rem);
                        }
                    }

                    .detail_arrow {
                        width: 10%;

                        img {
                            width: 100%;
                        }
                    }
                }
            }

            .design_image_div5 {


                .design_img {
                    width: 100%;
                    height: 300px;
                    background-image: url(../images/service_4.webp);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    border: solid 1px gray;
                    // img{
                    //     width: 100%;
                    // }

                    // .service_2{
                    //     height: 400px;
                    // }

                }

                .design_details {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;

                    .details_for_design {
                        width: 87%;

                        h3 {
                            text-align: left;
                            color: #D9592F;
                            margin: 35px 0 10px 0;
                        }

                        p {
                            margin: 0;
                            text-align: left;
                            font-size: clamp(.6rem, 1vw, .8rem);
                        }
                    }

                    .detail_arrow {
                        width: 10%;

                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }



    }

    .banner_flex {
        width: 33%;
        height: 40dvh;
    }

    .explore_div {
        text-align: left;
        margin-top: 250px;

        h1 {

            font-size: 30px;
            margin-bottom: 0;
            text-align: center;
            color: #D9592F;
        }

        p {
            font-size: 12px;
            margin-top: 0;
            text-align: center;

        }
    }

    .gradient_flex {
        width: 60%;
        margin: auto;
        margin-top: 35px;


        p {
            border-bottom: 1px solid rgba(128, 128, 128, 0.253);

        }
    }


    .banner_last_img {
        width: 75%;
        margin: auto;
        display: flex;
        justify-content: space-between;
        margin-bottom: 200px;
        margin-top: 40px;
        gap: 15px;

        a {
            width: 30%;
            text-decoration: none;
            color: black;

            .project_view1 {



                .project_view_img {
                    width: 100%;
                    height: 300px;
                    background-image: url(../images/project1.webp);
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                }

                .text_in_project {
                    h4 {
                        text-align: left;
                        color: #D9592F;
                        margin-bottom: 0;
                        font-family: $helevetica-bold;
                    }

                    p {
                        font-size: .8rem;
                        text-align: left;
                    }
                }


            }


            .project_view2 {


                .project_view_img {
                    width: 100%;
                    height: 300px;
                    background-image: url(../images/project3.webp);
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                }

                .text_in_project {
                    h4 {
                        text-align: left;
                        color: #D9592F;
                        margin-bottom: 0;
                        font-family: $helevetica-bold;
                    }

                    p {
                        font-size: 14px;
                        text-align: left;
                    }
                }


            }


            .project_view3 {

                .project_view_img {
                    width: 100%;
                    height: 300px;
                    background-image: url(../images/abetitfi_container/render_1.webp);
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                }

                .text_in_project {
                    h4 {
                        text-align: left;
                        color: #D9592F;
                        margin-bottom: 0;
                        font-family: $helevetica-bold;
                    }

                    p {
                        font-size: 14px;
                        text-align: left;
                    }
                }


            }

            .project_view4 {

                .project_view_img {
                    width: 100%;
                    height: 300px;
                    background-image: url(../images/rosey/render_1.webp);
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                }

                .text_in_project {
                    h4 {
                        text-align: left;
                        color: #D9592F;
                        margin-bottom: 0;
                        font-family: $helevetica-bold;
                    }

                    p {
                        font-size: 14px;
                        text-align: left;
                    }
                }


            }
        }



    }


}